<template lang="">
  <div>
    <BasicInfo isshowYP :projectCode="projectCode"></BasicInfo>
    <CollapseItem
      title="最新标记"
      icon="/images/icon-tag@2x.png"
      name="4"
      :value="recordListFirst.followTime | dash"
      disabled
      :is-link="false"
    >
      <div class="item">
        <span class="label large">回款可能性：</span>
        <span class="value">{{
          recordListFirst.paymentPossibilityStr | dash
        }}</span>
      </div>
      <div class="item">
        <span class="label large">是否投诉客户：</span>
        <span class="value">{{
          recordListFirst.complaintFlag == "YES" ? "是" : "否"
        }}</span>
      </div>
      <div class="item">
        <span class="label large">车辆情况：</span>
        <!-- <span class="value">{{
          `${commonStatus.collectionFlagStr || "-"}-${
            commonStatus.collectionStatusStr || "-"
          }-${commonStatus.gpsStatus || "-"}`
        }}</span> -->
        <span class="value">{{
          recordListFirst.collectionDetails | dash
        }}</span>
      </div>
      <div class="item">
        <span class="label large">客户情况：</span>
        <span class="value">{{
          recordListFirst.customeConditionDetails | dash
        }}</span>
      </div>
      <div class="item">
        <span class="label large">法诉情况：</span>
        <span class="value">{{ recordListFirst.legalDetails | dash }}</span>
      </div>
      <div class="item">
        <span class="label large">律师函：</span>
        <span class="value">{{
          recordListFirst.lawyerLetterDetails | dash
        }}</span>
      </div>
      <div class="item">
        <span class="label large">其他(备注)：</span>
        <span class="value">{{ recordListFirst.paymentContent | dash }}</span>
      </div>
      <div class="item">
        <span class="label large">跟进内容：</span>
        <span class="value">{{ recordListFirst.followContent | dash }}</span>
      </div>
    </CollapseItem>
    <CollapseItem
      title="调度信息"
      icon="/images/icon-dispatch@2x.png"
      name="5"
      disabled
      :is-link="false"
    >
      <div class="dispatch">
        <div class="title">
          <div class="left">委外收车</div>
          <div class="right">
            <Button
              type="primary"
              hairline
              size="mini"
              v-if="
                ['INIT', 'CANCEL_TO_INIT'].includes(outsourceCase.status) &&
                $authCheck('SCDispatchMobileH5CaseDispatch')
              "
              @click="
                $router.push({
                  path: '/caseDispatch?projectCode=' + projectCode,
                })
              "
              >案件分派</Button
            >
          </div>
        </div>
        <div
          class="item"
          v-if="
            outsourceCase.mode == 'SHARED' &&
            commonStatus.outsourceStatus == 'PROCESSING' &&
            !['INIT', 'CANCEL_TO_INIT', 'END', 'CANCELED'].includes(
              outsourceCase.status
            ) &&
            $authCheck('SCDispatchMobileH5CaseAudit')
          "
          @click="getOutsourceCaseApi()"
        >
          <span class="label large">共享清收</span>
          <Button type="primary" hairline size="mini">认证审核</Button>
        </div>
        <div
          class="item"
          v-if="
            outsourceCase.mode == 'SPECIFY' &&
            ![
              'INIT',
              'CANCEL_TO_INIT',
              'TODO_CLAIM',
              'END',
              'CANCELED',
            ].includes(outsourceCase.status) &&
            $authCheck('SCDispatchMobileH5CaseCancel')
          "
        >
          <span class="label large">指定分配</span>
          <Button
            type="danger"
            hairline
            size="mini"
            @click="
              reason = '';
              isShowMessage = true;
            "
            >取消</Button
          >
        </div>
        <div class="item">
          <span class="label large">状态：</span>
          <span class="value">{{
            commonStatus.outsourceStatusDetail | dash
          }}</span>
        </div>
        <div class="item">
          <span class="label large">收车委托函：</span>
          <div class="value">
            <Button
              plain
              type="primary"
              hairline
              icon="/images/icon-file@2x.png"
              size="mini"
              @click="checkFile('getOutsourceAuthorization')"
              >查看</Button
            >
          </div>
        </div>
      </div>
      <div class="dispatch">
        <div class="title">商户收车</div>
        <div class="item">
          <span class="label large">状态：</span>
          <span class="value">{{
            commonStatus.merchantCollectionStatusDetail | dash
          }}</span>
        </div>
        <div class="item">
          <span class="label large">收车委托函：</span>
          <div class="value">
            <Button
              plain
              type="primary"
              hairline
              icon="/images/icon-file@2x.png"
              size="mini"
              @click="checkFile('getMerchantCollectionAuthorization')"
              >查看</Button
            >
          </div>
        </div>
      </div>
      <div class="dispatch">
        <div class="title">内部电催</div>
        <div class="item">
          <span class="label large">状态：</span>
          <span class="value"
            >{{ commonStatus.collectionResult | dash }} -
            {{ commonStatus.collectorName | dash }} -
            {{ commonStatus.collectionDate | dash }}</span
          >
        </div>
      </div>
      <div class="dispatch">
        <div class="title">委外电催</div>
        <div class="item">
          <span class="label large">状态：</span>
          <span class="value">{{ commonStatus.phoneStatusDetail | dash }}</span>
        </div>
      </div>
      <div class="dispatch">
        <div class="title">法诉</div>
        <div class="item">
          <span class="label large">状态：</span>
          <span class="value"
            >{{ commonStatus.legalStatusDisplayName | dash }}-{{
              commonStatus.legalKeptStatusDisplayName | dash
            }}</span
          >
        </div>
        <div class="item" v-if="$authCheck('SCDispatchMobileH5CaseFiles')">
          <span class="label large">法诉附件：</span>
          <div class="value">
            <Button
              plain
              type="primary"
              hairline
              icon="/images/icon-file@2x.png"
              v-if="commonStatus.legalBizNo"
              size="mini"
              @click="checkFiles()"
              >查看</Button
            >
          </div>
        </div>
      </div>
    </CollapseItem>
    <PdfViewer ref="PdfViewer" :pdfUrl="pdfUrl"></PdfViewer>
    <ActionSheet v-model="isShowMessage" title="取消原因">
      <Field
        v-model="reason"
        type="textarea"
        :row="2"
        maxlength="200"
        label="取消原因"
        placeholder="请输入"
        show-word-limit
      />
      <div class="messageBtn">
        <Button
          type="primary"
          hairline
          icon="/images/icon-file@2x.png"
          size="small"
          @click="cancelButton"
          >确认</Button
        >
      </div>
    </ActionSheet>
    <Popup v-model="show" closeable :close-on-click-overlay="false">
      <div class="popup-title">请选择</div>
      <ul class="popup-list">
        <li class="popup-list-item" v-for="(item, index) in list" :key="index">
          <div class="top">
            <div class="left">
              {{ item.orgName }}
            </div>
          </div>
          <div class="bottom">
            <div class="legt">{{ item.refTime }}</div>
            <div class="right">
              <Button size="mini" type="primary" @click="check(item)"
                >选择</Button
              >
            </div>
          </div>
        </li>
      </ul>
    </Popup>
  </div>
</template>
<script>
import PdfViewer from "@/components/PdfViewer.vue";
import BasicInfo from "./BasicInfo.vue";
import { mapState } from "vuex";
import {
  CollapseItem,
  Button,
  ImagePreview,
  Toast,
  ActionSheet,
  Field,
  Popup,
} from "vant";
import Api from "@/api/index.js";

export default {
  props: {
    projectCode: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      pdfUrl: "",
      recordListFirst: {},
      commonStatus: {},
      outsourceCase: {},
      isShowMessage: false,
      show: false,
      reason: "",
      list: [],
    };
  },
  components: {
    CollapseItem,
    Button,
    PdfViewer,
    BasicInfo,
    ActionSheet,
    Field,
    Popup,
  },
  // activated() {
  //   this.getOutsourceCase()
  // },
  created() {
    this.init();
  },
  computed: {
    ...mapState(["info"]),
  },
  methods: {
    async getOutsourceCaseApi() {
      const toast = Toast.loading({
        message: "加载中...",
        duration: 0,
        forbidClick: true,
      });
      try {
        let { data, success, message } = await Api.getOutsourceCaseApi({
          caseNo: this.outsourceCase.caseNo,
        });
        if (!success) throw message;
        let list = data.list || [];
        list = list.filter((i) =>
          ["APPLY_REGAIN", "TODO_CERTIFY", "TODO_CLOSE_CASE"].includes(i.status)
        );
        if (list && list.length) {
          if (list.length > 1) {
            this.list = list;
            this.show = true;
          } else {
            let item = list[0];
            this.check(item);
          }
        } else {
          Toast("暂无审核数据");
        }
        toast.clear();
      } catch (error) {
        Toast(error.message || error);
        toast.clear();
      }
    },
    init() {
      Api.findRecordList({ applyBizNoEq: this.projectCode }).then(
        ({ data }) => {
          this.recordListFirst = (data && data[0]) || {};
        }
      );
      this.getCommonStatus();
      this.getOutsourceCase();
    },
    getCommonStatus() {
      Api.getCommonStatus(this.projectCode).then((res) => {
        this.commonStatus = res.data || {};
      });
    },
    getOutsourceCase() {
      Api.getOutsourceCase(this.projectCode).then(({ data }) => {
        this.outsourceCase = data || {};
      });
    },
    checkFile(type) {
      Api[type](this.projectCode).then(({ data, success }) => {
        if (success) {
          if (data) {
            this.viewerShow(data);
          } else {
            Toast("暂无附件");
          }
        }
      });
    },
    viewerShow(data) {
      if (data && data.endsWith(".pdf")) {
        this.pdfUrl = data;
        return this.$refs.PdfViewer.show();
      } else {
        ImagePreview({
          images: [data],
          startPosition: 0,
          closeable: true,
        });
      }
    },
    checkFiles() {
      Api.attchment(this.commonStatus.legalBizNo).then(({ data, success }) => {
        if (success) {
          if (data.length > 1) {
            this.$router.push({
              path: "/annex?legalBizNo=" + this.commonStatus.legalBizNo,
            });
          } else if (data.length == 1) {
            this.viewerShow(data[0].attachmentUrl);
          } else {
            Toast("暂无附件");
          }
        }
      });
    },
    async cancelButton() {
      if (this.reason) {
        const toast = Toast.loading({
          message: "请求中...",
          duration: 0,
          forbidClick: true,
        });
        try {
          let { success, message } = await Api.cancel({
            caseNo: this.outsourceCase.caseNo,
            // orgNo: this.outsourceCase.orgNo,
            reason: this.reason,
            operatorld: this.info.userId,
            operatorName: this.info.username,
          });
          if (success) {
            this.isShowMessage = false;
            this.getOutsourceCase();
            this.getCommonStatus();
          }
          Toast(message);
          toast.clear();
        } catch (error) {
          toast.clear();
        }
      } else {
        Toast("请输入取消原因");
      }
    },
    check(row) {
      let item = row || {};
      this.$router.push({
        path: `/caseAudit?projectCode=${
          this.projectCode
        }&item=${encodeURIComponent(JSON.stringify(item))}`,
      });
    },
  },
};
</script>
<style lang="less" scoped>
.van-collapse-item {
  border-radius: 4px;
  margin-bottom: 12px;
  overflow: hidden;
  box-shadow: 0px 0px 4px 0px rgba(0, 6, 42, 0.08);
  &:last-child {
    margin-bottom: 0;
  }
  /deep/ .van-cell__title {
    color: #323233 !important;
    font-weight: bold;
  }
  .dispatch {
    background-color: #f5f7f9;
    padding: 12px;
    margin-bottom: 12px;
    border-radius: 2px;
    &:last-child {
      margin-bottom: 0;
    }
    .title {
      color: #333333;
      font-weight: 600;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .right {
        button {
          padding: 0 12px;
        }
      }
    }
    .item {
      align-items: center;
    }
  }
  .item {
    display: flex;
    overflow: hidden;
    line-height: 24px;
    span {
      display: block;
    }
    .label {
      flex: 0 0 80px;
      color: #666;
      &.large {
        flex: 0 0 100px;
      }
    }
    .value {
      flex: 1;
      color: #333;
    }
  }
}
/deep/.messageBtn {
  width: 100%;
  text-align: center;
  margin: 10px auto;
  button {
    width: 50%;
    margin: 0 auto;
  }
}
.van-popup--center {
  width: 80%;
  min-height: 50vh;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  .popup-title {
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    flex: 0 0 50px;
    text-align: center;
    border-bottom: 1px solid #e7e7e7;
  }
  .popup-list {
    overflow: auto;
    flex: 1;
    margin: 12px;
    .popup-list-item {
      padding: 12px;
      font-size: 16px;
      margin-bottom: 12px;
      background-color: #efefef;
      border-radius: 4px;
      .top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        .left {
          span {
            font-size: 12px;
          }
        }
      }
      .bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}
</style>
